.swiper-pagination-bullet{
    background: "#4BA18A50"!important;
    margin-top: 5px !important;
    top: 5px !important;
}

.swiper-pagination-bullet-active{
   
    background-color: "#4BA18A"!important;
}

.swiper{
    z-index: 0;
}

@media(max-width: 768px){
    .ob-main-container{
        height: 100vh;
        margin-top: -5px !important;
    }
}